import {findAll, on} from '@elements/dom-utils';

export function init() {
    let handle = setInterval(function () {
        let closeBtn = findAll('.lg-close');
        if (closeBtn.length > 0) {
            closeBtn.map(function (btn) {

                on('click', function () {
                    dataLayer.push({
                        'event': 'landingpage',
                        'type': 'Bilderslider',
                        'click_text': 'schließen'
                    });
                }, btn);
            });
            clearInterval(handle);
        }
    }, 200);
}

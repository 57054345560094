export function init(seoJson, lang, pageType, spId, town) {
    // override / set hreflang
    if (seoJson && seoJson.documents) {
        for (const [key, value] of Object.entries(seoJson.documents)) {
            setHrefLangTags(key, value, !!spId);
        }
    }

    // override / set canonical
    if (seoJson && seoJson.canonical) {
        if (seoJson.canonical[pageType]) {
            if (pageType === 101) {
                if (seoJson.canonical[pageType][town.toLowerCase().replace('ü', 'ue')]) {
                    setCanonicalTag(seoJson.canonical[pageType][town.toLowerCase().replace('ü', 'ue')], !!spId)
                }
            } else {
                setCanonicalTag(seoJson.canonical[pageType], !!spId)
            }
        }
    }

    // override / set robots
    if (seoJson && seoJson.robots && seoJson.robots.content) {
        if (seoJson.robots.pageTypes.indexOf(pageType) > -1) {
            let robotsSelector = "meta[name='robots']";
            let robots = !!document.querySelector(robotsSelector) ? document.querySelector(robotsSelector) : document.createElement('meta');

            robots.setAttribute('name', 'robots');
            robots.setAttribute('content', seoJson.robots.content);
            document.head.appendChild(robots);
        }
    }

    function setHrefLangTags(lang, url, isDetail) {
        let hrefSelector = "link[rel='alternate'][hreflang='" + lang + "'";
        let hreflang = !!document.querySelector(hrefSelector) ? document.querySelector(hrefSelector) : document.createElement('link');

        hreflang.setAttribute('rel', 'alternate');
        hreflang.setAttribute('hreflang', lang);
        hreflang.setAttribute('href', getToscPath(url, isDetail, lang));
        document.head.appendChild(hreflang);
    }

    function setCanonicalTag(url) {
        let canonicalSelector = "link[rel='canonical']";
        let canonical = !!document.querySelector(canonicalSelector) ? document.querySelector(canonicalSelector) : document.createElement('link');

        let tosc5Path = document.location.pathname.split('/').slice(-3);
        let newCanonical = url + '/' + tosc5Path.join('/');

        canonical.setAttribute('rel', 'canonical');
        canonical.setAttribute('href', newCanonical);
        document.head.appendChild(canonical);
    }

    function getToscPath(url, isDetail, toLanguage = null) {

        let tosc5Path;

        if (isDetail) {
            tosc5Path = document.location.pathname.split('/').slice(-4)

            if (toLanguage !== null) {
                tosc5Path[0] = seoJson.targetRoutes[toLanguage];
            }
        } else {
            tosc5Path = document.location.pathname.split('/').slice(-1)

            if (toLanguage !== null) {
                tosc5Path[0] = seoJson.targetRoutes[toLanguage];
            }
        }

        return url + '/' + tosc5Path.join('/')
    }
}

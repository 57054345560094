import 'pannellum';
import {onEnterViewPort} from '@elements/viewport-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {findAll} from "@elements/dom-utils";

// the module is not exported but is stored in the window object
const pannellum = window['pannellum'];

let id = 0;
export function init() {
    onFind('.js-panorama-viewer', function (scope) {
        const panoramaViewers = findAll('.js-panorama-viewer');
        panoramaViewers.forEach(function (element) {
            let currentId = `panorama-viewer-${id++}`;
            element.setAttribute('id', currentId);
            let imageUrl = element.getAttribute('data-panorama-viewer-image-url');
            let viewer = pannellum.viewer(currentId, {
                "panorama": imageUrl
            });
            onEnterViewPort(element, function () {
                viewer.loadScene();
            });
        });
    });
}
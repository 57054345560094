import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, is,setAttribute, removeAttribute, findIn} from "@elements/dom-utils";

export function init() {
    onFind('.js-conditional-form', function (form) {
        let formParts = findAllIn('.js-conditional-form__part', form);

        let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',form);
        optionalRequiredFields.map((element) => {
            element.required = false;
        });

        on('change', function(event) {
            formParts.map(setAttribute('hidden', 'hidden'));

            let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',form);
            optionalRequiredFields.map((element) => {
                element.required = false;
            });

            let formData = serializeArray(form);

            formData
                .filter(({name, value}) => !!value)
                .forEach(({name, value}) => {
                    let currentParts = formParts.filter(is(`[data-name="${name}"]`));
                    currentParts.filter(is(`[data-value="${value}"]`)).map(function(currentPart) {
                        let hiddenInput = findIn('.js-conditional-form__hidden-input', form);
                        let selectedValue = findIn('option:checked', currentPart);
                        if(hiddenInput){
                            hiddenInput.value = selectedValue.value;
                        }

                        removeAttribute('hidden', currentPart);

                        let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',currentPart);
                        optionalRequiredFields.required = true;

                        optionalRequiredFields.map((element) => {
                            element.required = true;
                        });
                    });
                });
        }, form);
    });
}

export function serializeArray(form) {
    let arr = [];
    Array.prototype.slice.call(form.elements).forEach(function (field) {
        if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;
        if (field.type === 'select-multiple') {
            Array.prototype.slice.call(field.options).forEach(function (option) {
                if (!option.selected) return;
                arr.push({
                    name: field.name,
                    value: option.value
                });
            });
            return;
        }
        if (['checkbox', 'radio'].indexOf(field.type) >-1 && !field.checked) return;
        arr.push({
            name: field.name,
            value: field.value
        });
    });
    return arr;
}



"use strict";

import * as googleMap from "@elements/google-map";
import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";


const isMobile = () => matchMedia('(max-width: 767px)').matches;


export function init () {
    let checkMapConsent = setInterval(function(){
        onFind('.js-google-map', function (mapElement) {
            clearInterval(checkMapConsent);
            let poiVarName = mapElement.dataset.googleMapPois;
            let pois = [];

            if (poiVarName) {
                if (!window['_googleMapPois']) {
                    console.error('_googleMapPois is not set');
                } else if (!window._googleMapPois[poiVarName]) {
                    console.error(poiVarName + ' is not set in _googleMapPois', _googleMapPois);
                } else {
                    pois = _googleMapPois[poiVarName];
                }
            }

            onEnterViewPort(mapElement, function (mapElement) {

                let map = googleMap.init({
                    element: mapElement,
                    pois: pois,
                    mapOptions: {
                        center: {lat: 47.076668, lng: 15.421371},
                        zoom: 14,
                        maxZoom: 18,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: false,
                        fullscreenControl: false,
                        scrollwheel: true,
                    }
                });

                googleMap.getApi(element).then((api) => {
                    api.centerMap();
                });
            }, {
                offset: 0
            });
        });
    }, 250)

}

import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();
import Collapse from 'bootstrap/js/dist/collapse';


import * as playVideo from './scripts/play-video';
playVideo.init();

import * as imageSlider from './scripts/image-slider';
imageSlider.init();

import * as imgContentSlider from './scripts/img-content-slider';
imgContentSlider.init();

import * as imageContentSlider from './scripts/image-content-slider';
imageContentSlider.init();

import * as contentSlider from './scripts/content-slider';
contentSlider.init();

import * as conditionalForm from "./scripts/conditional-form";
conditionalForm.init();

import * as cardTeaserSlider from './scripts/card-teaser-slider';
cardTeaserSlider.init();

import * as imgTeaserSlider from './scripts/img-teaser-slider';
imgTeaserSlider.init();

import * as tosc5Seo from './scripts/tosc5-seo';
app.tosc5Seo = tosc5Seo.init;

import * as rotateCircle from './scripts/rotate-circle';
rotateCircle.init();

import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger: { event: 'false'}
});

import * as googleMap from "./scripts/google-map";
googleMap.init();

import * as parallax from "./scripts/parallax";
parallax.init();

import * as panoramaViewer from "./scripts/panorama-viewer";
panoramaViewer.init();

import * as circleAnimation from './scripts/circle-animation';
circleAnimation.init();

import * as lightboxTracking from './scripts/lightbox-tracking';
lightboxTracking.init();

import * as datepickerRange from "@elements/datepicker-range";
datepickerRange.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as fileInput from '@elements/file-input';
fileInput.init();

import * as fileInputReset from './scripts/file-input-reset';
fileInputReset.init();

import Popover from 'bootstrap/js/dist/popover';

import {onFind} from "@elements/init-modules-in-scope";
onFind('[data-bs-toggle="popover"]',function (element) {
    new Popover(element);
});

import * as wishlist from './scripts/wishlist';
wishlist.init();



"use strict";
import * as wishlistbundle from "@elements/wishlist";
import {findAllIn, find, findIn, addClass, removeClass, findAll, on} from "@elements/dom-utils";
import {onFind } from "@elements/init-modules-in-scope";

let wishlist = null;
if (window['_wishlist']) {
    wishlist = wishlistbundle.createWishlist({
        ...window['_wishlist'],
        notificationOptions: {
            $container: find('.js-wishlist__notification'),
        }
    })
}

let myWishlists = [];
if (window['_wishlists']) {
    for(let i = 0; i < window['_wishlists'].length; i++){
        myWishlists.push(wishlistbundle.createWishlist({
            ...window['_wishlists'][i],
            notificationOptions: {
                $container: find('.js-wishlist__notification'),
            }
        }, {
            item: '.js-wishlist__btn',
            itemId: 'wishlist-id',
            wishlistId: 'wishlist-list',
            itemActiveClass: 'is-active'
        }));
    }
}

export function init() {
/*    if(wishlist) {
      onFind('.js-wishlist-form', function (element) {
            let scope = find('body');
            console.log("myWishlists");
            let wishListContainer = findAllIn('.js-wishlist__container', scope);

            wishListContainer.forEach((el) => {
                const container = el,
                    button = findIn('.js-wishlist__remove', container),
                    id = container.getAttribute('data-wishlist-id');

                const buttonClickHandler = (evt, button) => {
                    console.log("click");
                    evt.preventDefault();
                    let _request = wishlist.remove(id, {showUndo: true});
                    _request.then(function (res) {
                        console.log(res);
                        if (res.data.success) {
                            console.log("success");
                            container.remove();
                        }
                    })
                };

                on('click', (evt) => buttonClickHandler(evt, button), button);

            });
        });


        }*/
    wishlist.init();
}

import {onFind} from "@elements/init-modules-in-scope";
import {closest, findIn, trigger, on, removeClass, addClass} from "@elements/dom-utils";

const defaultSelectors = {
    input: '.js-file-input',
    label: '.js-file-input__text',
    removeButton: '.js-file-input__remove'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.input, function (base) {
        createInputText(
            base,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createInputText(base, selectors = defaultSelectors) {

    on('change', function() {
        handleChange(base);
    }, base);

    function handleChange(base) {
        let formGroup  = base,
            input      = findIn('[type="file"]', base),
            removeButton  = findIn(selectors.removeButton, base),
            label      = findIn(selectors.label, base),
            numFiles    = input.files ? input.files.length : 1,
            labelText       = input.value.replace(/\\/g, '/').replace(/.*\//, '');

        on('click', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            input.value = '';
            trigger('change', input);
            label.innerText = base.getAttribute('data-file-input-default-text');
            removeClass('has-value', input);
            removeClass('has-value', formGroup);
            removeButton.setAttribute('hidden', 'hidden');
        }, removeButton);

        if(numFiles >= 1) {
            removeButton.removeAttribute('hidden');
            addClass('has-value', input);
            addClass('has-value', formGroup);
        } else {
            removeButton.setAttribute('hidden', 'hidden');
        }

        if (numFiles === 0) {
            labelText = base.getAttribute('data-file-input-default-text');
            removeClass('has-value', input);
            removeClass('has-value', formGroup);
        } else if (numFiles > 1) {
            labelText = base.getAttribute('data-file-input-multiple-text').replace('[count]', numFiles);

        }
        label.innerText = labelText;
    }
}
